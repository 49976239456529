<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="event && regConfig">
      <EventHeader :event="event"/>

      <v-card-title>{{$t('events.reg.completed-page-title', { event: event.name })}}</v-card-title>

      <v-alert v-if="!$store.getters.isLoading && result && result.status == 'ERROR'" type="error" class="mx-4">
        <p>{{ result.msg }}</p>
        <p>{{$t('events.welcome.verification-code-mismatch')}}</p>
      </v-alert>
  
      <v-alert v-if="paymentStatus && paymentStatus.status == 'OK'" type="success" tile>
        <p>Thanks! Your payment has been received successfully.</p>
      </v-alert>
      <v-alert v-else-if="status == 'cancelled' || (paymentStatus && paymentStatus.status == 'ERROR')" type="error" tile>
        Payment was cancelled or unsuccessful.
      </v-alert>
      <v-alert v-else-if="status == 'check-payment'" type="info" tile>
        <v-progress-circular indeterminate :size="25" :width="5" color="white" class="mr-4"></v-progress-circular>
        Loading payment status... One minute...
      </v-alert>

      <v-card-text v-if="status == 'cancelled' || (paymentStatus && paymentStatus.status == 'ERROR')">
        <v-btn outlined color="primary" class="me-8" :to="{name: 'eventRegConfirm', params: {id: event.id, code: code }}">{{ $t('shared.try-again')}}</v-btn>
      </v-card-text>

      <v-card-text v-if="paymentStatus && paymentStatus.status == 'OK'">
        <v-btn v-if="isEmbeddedInApp" large color="primary" class="me-8" :to="{name: 'event', params: {id: event.id }, query: { appcmd: 'close'}}">{{ $t('shared.done')}}</v-btn>
        <v-btn v-else-if="tenant.allowEventSelfService" large color="primary" class="me-8" :to="{name: 'eventWelcome', params: {id: event.id, code: code }}">{{ $t('events.welcome.personalize')}}</v-btn>
        <v-btn v-else large color="primary" class="me-8" :to="{name: 'event', params: {id: event.id }}">{{ $t('shared.view-details')}}</v-btn>
      </v-card-text>
      <br/>
    </v-card>

  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { DateTime } from 'luxon'
import eventService from "@/services/eventService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import ToggleButtonInput from '@/components/ToggleButtonInput.vue'
import EventHeader from '@/components/EventHeader.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import profileService from "@/services/profileService";
import checkoutService from "@/services/checkoutService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
import { getAuth } from "firebase/auth";
import siteData from '@/data/site.json'

export default {
  name: "EventJoin",
  components: {
    VueMarkdown,
    ToggleButtonInput,
    EventHeader,
    EventJoinCard,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      code: null,
      reg: null,
      result: null,
      valid: true,
      status: null,
      paymentStatus: null,
    };
  },

  async mounted() {
    this.code = this.$route.params.code;
    this.status = this.$route.query.status;
    await this.loadEvent(this.$route.params.id, this.$route.params.code);

    EventBus.$on('login-state-change', async user => {
      await this.getLoginStatus();
    });
    await this.getLoginStatus();


  },

  methods: {
      
    async getLoginStatus() {
      if (this.user) {
        var regResponse = await eventService.getRegistrationSummary(this.$route.params.id, this.$route.params.code);
        this.reg = regResponse.data;
        
        if (this.status === 'check-payment') {
          await this.getPaymentStatus();
        }
      }
    },

    async loadEvent(id, code) {
      var response = await eventService.get(id);
      this.event = response.data;
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    
    async getPaymentStatus() {
      if (!this.event) {
        return;
      }

      this.paymentStatus = (await checkoutService.getRegistrationSessionStatus(this.event.id, this.code)).data;
      if (this.paymentStatus.status === 'INFO') {
        // schedule a next refresh in a few seconds
        setTimeout(this.getPaymentStatus, 5 * 1000);
      }
      else if (this.paymentStatus.status === 'OK') {
        this.$analytics.event('purchase', {
          currency: 'USD',
          value: this.paymentStatus.value,
          transaction_id: this.paymentStatus.id,
        });
      }
    },

  },
  computed: {
    regConfig() {
      return this.event && this.event.reg_config;
    },
    regOption() {
      return this.regConfig && this.regConfig.options && this.reg && this.reg.ro && this.regConfig.options.find(x => x.id == this.reg.ro);
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('events.reg.title'), disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .markdown-content {
    p { margin-bottom: 0 !important;}
  }
</style>

